.notif-list-title {
  font-size: small;
  font-weight: bold;
}

p.notif-list-body {
  font-size: small;
}

ion-item.notif-item {
  --inner-padding-end: 0
}

.btn-dismiss-notif {
  margin-inline-start: 0;
  height: 100%;
}
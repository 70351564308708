.text-bold {
  font-weight: bold !important;
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

ion-modal.fullsize-modal {
  --width: 100%;
  --height: 100%;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

ion-modal.popup-dialog {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.full-width-dialog {
  --width: 100%;
}

ion-modal.full-height-dialog {
  --height: 100%;
}

ion-modal.full-dialog {
  --width: 100%;
  --height: 100%;
}

.qr-box {
  width: 240px;
  height: 240px;
  border: 4px solid rgb(0, 119, 255);
  margin: auto;
  background-color: skyblue;
  opacity: 0.2;
  position: relative;
  margin-bottom: 80px;
}

@keyframes scanlinemove {
  0%, 20% {
    top: 0;
    opacity: 0%;
  }
  50% {
    top: 50%;
    opacity: 100%;
  }
  80%, 100% {
    top: 100%;
    opacity: 0%;
  }
}

.scan-line {
  width: 100%;
  border: 2px solid red;
  animation: scanlinemove 1.5s linear infinite;
  animation-delay: 0.5s;
  position: absolute; /* Add this line to position the element within the qr-box */
  left: 0; /* Add this line to align the element to the left */
}